import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

import * as commonStyles from "../styles/common.module.css";

const FadeInSection = (props) => {
  const { delay = 0, children } = props;

  return (
    <ScrollAnimation animateIn={commonStyles.fadeIn} animateOnce={true} delay={200}>
      {children}
    </ScrollAnimation>
  );
};

export default FadeInSection;
